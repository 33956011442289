import { useEffect, useState } from "react";
import { IconCards } from "./icons/IconCards";
import { IconCardsOutline } from "./icons/IconCardsOutline";
import usePersistentStore from "../lib/store/PersistentStore";

export function PromoBanner() {
  const [cardMode, toggleCardMode] = usePersistentStore((state) => [
    state.cardMode,
    state.toggleCardMode,
  ]);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (cardMode) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [cardMode]);

  function toggleMode() {
    toggleCardMode();
  }

  return (
    // <div className="flex w-screen items-center justify-center bg-black p-2 px-6 text-center text-sm font-medium text-gray-800 sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}
    //   <span className="mr-2 font-normal text-purple-50 ">Today's grid by</span>
    //   <a
    //     className="whitespace-nowrap text-blue-600 underline dark:text-blue-400 "
    //     href="https://www.aflmint.com.au/"
    //     target="_blank"
    //   >
    //     <img className="h-4" src="/img/partners/aflmint/aflmint.png" />
    //   </a>
    // </div>
    // <div className="w-screen bg-[#005979] p-2 text-center text-sm font-medium text-white sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}
    //   WORPEL is back with new players –{" "}
    //   <a
    //     className="whitespace-nowrap text-white underline dark:text-blue-400 "
    //     href="https://playworpel.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=banner"
    //     target="_blank"
    //   >
    //     Play now!
    //   </a>
    // </div>
    <div className="w-screen bg-gray-800 p-2 text-center text-sm text-white sm:text-sm md:p-3">
      {/* <span className="hidden md:inline-block">Watch Now</span> */}
      Gridley Help -{" "}
      <a
        className="whitespace-nowrap font-medium text-white underline dark:text-white"
        href="https://theinsideword.blog/2024/08/27/afl-players-that-were-selected-in-just-one-all-australian-team/?utm_source=gridley&utm_medium=affiliate&utm_campaign=banner"
        target="_blank"
      >
        Players that made just ONE AA team
      </a>
    </div>
  );
}
