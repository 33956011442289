export function LogoCaptians(props) {
  return (
    <svg
      viewBox="0 0 402 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61.216 68.6C59.6827 69.1333 57.716 69.5667 55.316 69.9C52.916 70.2333 50.6494 70.4 48.516 70.4C41.9827 70.4 37.1494 69.3333 34.016 67.2C30.9494 65 28.916 61.5333 27.916 56.8C26.916 52 26.416 44.8 26.416 35.2C26.416 25.6667 26.916 18.5 27.916 13.7C28.9827 8.9 31.0493 5.43333 34.116 3.3C37.2494 1.1 42.0494 0 48.516 0C50.8494 0 53.1827 0.166667 55.516 0.5C57.916 0.833333 59.7494 1.23333 61.016 1.7V15.4C56.816 15 53.8827 14.8 52.216 14.8C49.4827 14.8 47.616 15.2 46.616 16C45.6827 16.8 45.0827 18.4333 44.816 20.9C44.616 23.3 44.516 28.0667 44.516 35.2C44.516 42.3333 44.616 47.1333 44.816 49.6C45.0827 52.0667 45.6827 53.7 46.616 54.5C47.616 55.2333 49.4827 55.6 52.216 55.6C55.7494 55.6 58.7494 55.4 61.216 55V68.6Z"
        fill="currentColor"
      />
      <path
        d="M97.248 69.6L95.648 57.9H83.148L81.548 69.6H63.848L76.448 0.800002H102.748L115.348 69.6H97.248ZM89.748 16.7H89.048L85.348 43.6H93.4481L89.748 16.7Z"
        fill="currentColor"
      />
      <path
        d="M119.408 0.800002H144.208C149.275 0.800002 153.075 1.66667 155.608 3.4C158.208 5.06667 159.942 7.6 160.808 11C161.742 14.3333 162.208 18.9667 162.208 24.9C162.208 30.5 161.808 34.9667 161.008 38.3C160.208 41.6333 158.508 44.2667 155.908 46.2C153.308 48.0667 149.408 49 144.208 49H137.408V69.6H119.408V0.800002ZM139.508 34.3C141.042 34.3 142.075 34.1333 142.608 33.8C143.208 33.4667 143.608 32.7 143.808 31.5C144.008 30.2333 144.108 28.0333 144.108 24.9C144.108 21.7 144.008 19.5 143.808 18.3C143.608 17.0333 143.208 16.2667 142.608 16C142.075 15.6667 141.042 15.5 139.508 15.5H137.408V34.3H139.508Z"
        fill="currentColor"
      />
      <path
        d="M176.348 69.6V16.1H164.448V0.800002H206.348V16.1H194.448V69.6H176.348Z"
        fill="currentColor"
      />
      <path
        d="M236.994 69.6L235.394 57.9H222.894L221.294 69.6H203.594L216.194 0.800002H242.494L255.094 69.6H236.994ZM229.494 16.7H228.794L225.094 43.6H233.194L229.494 16.7Z"
        fill="currentColor"
      />
      <path
        d="M259.154 69.6V0.800002H277.154V69.6H259.154Z"
        fill="currentColor"
      />
      <path
        d="M313.831 69.6L302.731 38.6H302.231V69.6H285.131V0.800002H302.431L312.031 27.5H312.631V0.800002H329.731V69.6H313.831Z"
        fill="currentColor"
      />
      <path
        d="M356.268 70.4C353.201 70.4 349.901 70.2 346.368 69.8C342.834 69.4667 339.834 69.0333 337.368 68.5V54.9C343.568 55.3667 348.134 55.6 351.068 55.6C353.134 55.6 354.634 55.5333 355.568 55.4C356.501 55.2667 357.168 54.9667 357.568 54.5C357.968 54.1 358.234 53.5333 358.368 52.8C358.501 52.0667 358.568 51.0333 358.568 49.7C358.568 47.7 358.434 46.3 358.168 45.5C357.901 44.6333 357.368 44 356.568 43.6C355.768 43.2 354.334 42.8333 352.268 42.5L343.768 40.9C340.968 40.3667 338.968 38.4667 337.768 35.2C336.634 31.8667 336.068 27.2 336.068 21.2C336.068 15.3333 336.934 10.8667 338.668 7.8C340.468 4.66667 342.868 2.6 345.868 1.6C348.934 0.533334 352.768 0 357.368 0C360.834 0 364.034 0.199999 366.968 0.599998C369.901 1 372.201 1.4 373.868 1.8V15.5C369.868 15.0333 365.768 14.8 361.568 14.8C359.634 14.8 358.201 14.8667 357.268 15C356.401 15.0667 355.734 15.3 355.268 15.7C354.801 16.1 354.468 16.6667 354.268 17.4C354.134 18.1333 354.068 19.2 354.068 20.6C354.068 22.2 354.134 23.3667 354.268 24.1C354.468 24.8333 354.834 25.3667 355.368 25.7C355.901 26.0333 356.801 26.3333 358.068 26.6L365.368 28C367.634 28.4667 369.534 29.2 371.068 30.2C372.668 31.2 373.968 32.9667 374.968 35.5C376.034 38.4333 376.568 42.3667 376.568 47.3C376.568 54.0333 375.701 59.0667 373.968 62.4C372.301 65.7333 370.068 67.9 367.268 68.9C364.468 69.9 360.801 70.4 356.268 70.4Z"
        fill="currentColor"
      />
      <path
        d="M34.8 158.6C33.2667 159.133 31.3 159.567 28.9 159.9C26.5 160.233 24.2333 160.4 22.1 160.4C15.5667 160.4 10.7333 159.333 7.6 157.2C4.53333 155 2.5 151.533 1.5 146.8C0.5 142 0 134.8 0 125.2C0 115.667 0.5 108.5 1.5 103.7C2.56667 98.9 4.63333 95.4333 7.7 93.3C10.8333 91.1 15.6333 90 22.1 90C24.4333 90 26.7667 90.1667 29.1 90.5C31.5 90.8333 33.3333 91.2333 34.6 91.7V105.4C30.4 105 27.4667 104.8 25.8 104.8C23.0667 104.8 21.2 105.2 20.2 106C19.2667 106.8 18.6667 108.433 18.4 110.9C18.2 113.3 18.1 118.067 18.1 125.2C18.1 132.333 18.2 137.133 18.4 139.6C18.6667 142.067 19.2667 143.7 20.2 144.5C21.2 145.233 23.0667 145.6 25.8 145.6C29.3333 145.6 32.3333 145.4 34.8 145V158.6Z"
        fill="currentColor"
      />
      <path
        d="M67.332 159.6V133H59.332V159.6H41.332V90.8H59.332V117H67.332V90.8H85.332V159.6H67.332Z"
        fill="currentColor"
      />
      <path
        d="M122.785 159.6L121.185 147.9H108.685L107.085 159.6H89.3852L101.985 90.8H128.285L140.885 159.6H122.785ZM115.285 106.7H114.585L110.885 133.6H118.985L115.285 106.7Z"
        fill="currentColor"
      />
      <path
        d="M144.945 159.6V90.8H162.945V144.3H178.145V159.6H144.945Z"
        fill="currentColor"
      />
      <path
        d="M183.422 159.6V90.8H201.422V144.3H216.622V159.6H183.422Z"
        fill="currentColor"
      />
      <path
        d="M221.898 159.6V90.8H257.198V105.6H239.898V117.5H254.598V132.2H239.898V144.8H257.198V159.6H221.898Z"
        fill="currentColor"
      />
      <path
        d="M293.177 159.6L282.077 128.6H281.577V159.6H264.477V90.8H281.777L291.377 117.5H291.977V90.8H309.077V159.6H293.177Z"
        fill="currentColor"
      />
      <path
        d="M338.713 132.4V118.3H358.913V158.2C356.38 158.867 353.28 159.4 349.613 159.8C346.013 160.2 342.647 160.4 339.513 160.4C332.98 160.4 328.08 159.433 324.813 157.5C321.547 155.5 319.28 152.067 318.013 147.2C316.747 142.267 316.113 134.933 316.113 125.2C316.113 115.467 316.78 108.167 318.113 103.3C319.447 98.3667 321.813 94.9333 325.213 93C328.68 91 333.847 90 340.713 90C343.313 90 346.313 90.2 349.713 90.6C353.113 91 356.113 91.5 358.713 92.1V105.8C352.313 105.133 347.98 104.8 345.713 104.8C341.58 104.8 338.78 105.133 337.313 105.8C335.913 106.4 334.98 108 334.513 110.6C334.047 113.2 333.813 118.067 333.813 125.2C333.813 131.867 333.947 136.567 334.213 139.3C334.48 142.033 335.013 143.867 335.813 144.8C336.68 145.667 338.147 146.1 340.213 146.1L343.013 146V132.4H338.713Z"
        fill="currentColor"
      />
      <path
        d="M366.527 159.6V90.8H401.827V105.6H384.527V117.5H399.227V132.2H384.527V144.8H401.827V159.6H366.527Z"
        fill="currentColor"
      />
    </svg>
  );
}
