export function LogoPhantom(props) {
  return (
    <svg
      viewBox="0 0 520 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 79.3272V0.755804H21.3377C26.922 0.755804 31.1249 2.33318 33.9464 5.48795C36.7679 8.61295 38.1787 13.1963 38.1787 19.2379V56.0237C38.1787 63.4641 36.8855 69.2082 34.2991 73.2558C31.7421 77.3034 27.2453 79.3272 20.8087 79.3272H0ZM15.6065 65.4433H18.2958C21.1467 65.4433 22.5721 64.0445 22.5721 61.2469V20.7112C22.5721 18.0921 22.2195 16.4106 21.5141 15.6665C20.8381 14.8927 19.442 14.5058 17.3259 14.5058H15.6065V65.4433Z"
        fill="#4B5563"
      />
      <path
        d="M44.5271 79.3272V0.755804H68.3336C72.3014 0.755804 75.2993 1.67842 77.3272 3.52366C79.3552 5.33914 80.7072 7.89866 81.3832 11.2022C82.0885 14.476 82.4412 18.3302 82.4412 22.7647C82.4412 27.0504 81.8975 30.4731 80.81 33.0326C79.752 35.5921 77.7387 37.3629 74.7702 38.3451C77.2097 38.851 78.9143 40.0862 79.8842 42.0504C80.8835 43.985 81.3832 46.4999 81.3832 49.5951V79.3272H66.0852V48.5683C66.0852 46.2766 65.615 44.8629 64.6745 44.3272C63.7634 43.7618 62.2791 43.479 60.2218 43.479V79.3272H44.5271ZM60.3099 29.8629H64.0573C66.2028 29.8629 67.2756 27.4969 67.2756 22.7647C67.2756 19.6993 67.0404 17.6903 66.5702 16.7379C66.0999 15.7856 65.2182 15.3094 63.925 15.3094H60.3099V29.8629Z"
        fill="#4B5563"
      />
      <path
        d="M85.4391 79.3272L92.9337 0.755804H119.253L126.616 79.3272H111.935L110.833 66.6487H101.486L100.517 79.3272H85.4391ZM102.589 54.104H109.642L106.248 14.1487H105.542L102.589 54.104Z"
        fill="#4B5563"
      />
      <path
        d="M131.377 79.3272V0.755804H162.414V16.0683H146.983V28.5683H161.62V43.7022H146.983V79.3272H131.377Z"
        fill="#4B5563"
      />
      <path
        d="M174.096 79.3272V15.8451H164.882V0.755804H198.829V15.8451H189.615V79.3272H174.096Z"
        fill="#4B5563"
      />
      <path
        d="M203.373 79.2857V0.714286H223.873C228.164 0.714286 231.559 1.68155 234.057 3.61607C236.584 5.5506 238.392 8.30357 239.479 11.875C240.567 15.4464 241.111 19.7024 241.111 24.6429C241.111 29.4048 240.655 33.5565 239.744 37.0982C238.833 40.6101 237.202 43.3333 234.85 45.2679C232.528 47.2024 229.222 48.1696 224.931 48.1696H218.627V79.2857H203.373ZM218.627 34.0625H219.508C222.389 34.0625 224.152 33.244 224.799 31.6071C225.445 29.9702 225.769 27.5595 225.769 24.375C225.769 21.3988 225.445 19.1071 224.799 17.5C224.181 15.8631 222.697 15.0446 220.346 15.0446H218.627V34.0625Z"
        fill="#9CA6B5"
      />
      <path
        d="M246.048 79.2857V0.714286H261.478V28.7946H268.797V0.714286H284.227V79.2857H268.797V43.6607H261.478V79.2857H246.048Z"
        fill="#9CA6B5"
      />
      <path
        d="M288.988 79.2857L296.483 0.714286H322.802L330.165 79.2857H315.484L314.382 66.6071H305.036L304.066 79.2857H288.988ZM306.138 54.0625H313.192L309.797 14.1071H309.092L306.138 54.0625Z"
        fill="#9CA6B5"
      />
      <path
        d="M334.926 79.2857V0.714286H350.885L358.027 38.3482V0.714286H373.017V79.2857H357.851L350.092 40V79.2857H334.926Z"
        fill="#9CA6B5"
      />
      <path
        d="M386.551 79.2857V15.8036H377.337V0.714286H411.283V15.8036H402.069V79.2857H386.551Z"
        fill="#9CA6B5"
      />
      <path
        d="M434.12 80C427.919 80 423.172 78.1101 419.88 74.3304C416.618 70.5208 414.987 65.0298 414.987 57.8571V20.5357C414.987 13.8095 416.603 8.70536 419.836 5.22321C423.099 1.74107 427.86 0 434.12 0C440.38 0 445.127 1.74107 448.36 5.22321C451.622 8.70536 453.253 13.8095 453.253 20.5357V57.8571C453.253 65.0298 451.608 70.5208 448.316 74.3304C445.053 78.1101 440.322 80 434.12 80ZM434.252 65.5357C436.604 65.5357 437.779 63.2292 437.779 58.6161V20.2679C437.779 16.3988 436.633 14.4643 434.34 14.4643C431.754 14.4643 430.461 16.4435 430.461 20.4018V58.7054C430.461 61.1458 430.755 62.9018 431.343 63.9732C431.93 65.0149 432.9 65.5357 434.252 65.5357Z"
        fill="#9CA6B5"
      />
      <path
        d="M459.514 79.2857V0.714286H483.144L489.669 48.6161L496.149 0.714286H520V79.2857H505.804V22.6786L496.899 79.2857H482.968L473.533 22.6786V79.2857H459.514Z"
        fill="#9CA6B5"
      />
    </svg>
  );
}
